import { addTrailingSlash } from "./addTrailingSlash";
import { removeTrailingSlash } from "./removeTrailingSlash";
import locales from "~~/config/locales";
import { Currency } from "~~/lib/types/Currency";

/**
 * Strips an locale or currency included in the path
 * eg. /au/usd/thailand becomes /thailand
 *
 * @param url The full path url
 * @returns The url without any locale or currency applied
 */
export const getBaseLink = (url: string) => {
  const urlWithTrailingSlash = addTrailingSlash(url);
  const urlStripped = urlWithTrailingSlash
    .replace(
      new RegExp(`^(/(${locales.map(({ code }) => code).join("|")})/)`, "g"),
      "/"
    )
    .replace(
      new RegExp(`^(/(${Object.values(Currency).join("|")})/)`, "g"),
      "/"
    );
  return removeTrailingSlash(urlStripped);
};
