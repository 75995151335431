/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Logger } from "winston";
import SearchProductModel from "search-client-js/src/Model/Product";
import { SalesCountry } from "~~/lib/types/Contentstack";
import { isBrowser } from "~~/lib/utils/isBrowser";
import { isNotNull } from "~~/lib/utils/isNotNull";
import { ParsedReq } from "~~/lib/utils/parseReq";

export enum LogLevel {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export const logTags = {
  App: {
    Nebula: "APP/NEBULA",
  },
  Env: {
    Server: "ENV/SERVER",
    Browser: "ENV/BROWSER",
  },
  Client: {
    Auth0: "CLIENT/AUTH0",
    AWS: "CLIENT/AWS",
    Salesforce: "CLIENT/SALESFORCE",
    Contentstack: "CLIENT/CONTENTSTACK",
    Redis: "CLIENT/REDIS",
    Wordpress: "CLIENT/WORDPRESS",
    Hayabusa: "CLIENT/HAYABUSA",
    NewRelic: "CLIENT/NEWRELIC",
    Algolia: "CLIENT/ALGOLIA",
    Formstack: "CLIENT/FORMSTACK",
  },
  Layer: {
    Component: "LAYER/COMPONENT",
    Api: "LAYER/API",
    Middleware: "LAYER/MIDDLEWARE",
    Service: "LAYER/SERVICE",
    Decorator: "LAYER/DECORATOR",
    Validator: "LAYER/VALIDATOR",
    Client: "LAYER/CLIENT",
    Cache: "LAYER/CACHE",
    Utils: "LAYER/UTILS",
    Routing: "LAYER/ROUTING",
    Store: "LAYER/STORE",
    Page: "LAYER/PAGE",
    AsyncProcessing: "LAYER/ASYNCPROCESSING",
    Plugin: "LAYER/PLUGIN",
    Nitro: "LAYER/NITRO",
  },
  Function: {
    ErrorBoundary: "FUNCTION/ERRORBOUNDARY",
    PageType: "FUNCTION/PAGETYPE",
  },
  Feature: {
    Agents: "FEATURE/AGENTS",
    Departures: "FEATURE/DEPARTURES",
    Reviews: "FEATURE/REVIEWS",
    DealPromotion: "FEATURE/DEALPROMOTION",
    TopDeals: "FEATURE/TOPDEALS",
    Products: "FEATURE/PRODUCTS",
    Header: "FEATURE/HEADER",
    Footer: "FEATURE/FOOTER",
    Webhook: "FEATURE/WEBHOOK",
    Wishlist: "FEATURE/WISHLIST",
    Search: "FEATURE/SEARCH",
    Webform: "FEATURE/WEBFORM",
    ContactUsMethods: "FEATURE/CONTACTUSMETHODS",
    Salesforce: "FEATURE/SALESFORCE",
  },
  Page: {
    ModularPage: "PAGE/MODULARPAGE",
    Home: "PAGE/HOME",
    Product: "PAGE/PRODUCT",
    ProductMap: "PAGE/PRODUCTMAP",
    Search: "PAGE/SEARCH",
    Destination: "PAGE/DESTINATION",
    Eti: "PAGE/ETI",
    Wishlist: "PAGE/WISHLIST",
    Wildlandtrekking: "PAGE/WILDLANDTREKKING",
    VisaEntryRequirements: "PAGE/VISAENTRYREQUIREMENTS",
    ContactUsPage: "PAGE/CONTACTUS",
    FAQPage: "PAGE/FAQ",
    CategoryPage: "PAGE/CATEGORY",
    StylePage: "PAGE/STYLEPAGE",
    ThemePage: "PAGE/THEME",
    TravelAlerts: "PAGE/TRAVELALERTS",
    DestinationMonths: "PAGE/DESTINATIONMONTHS",
    ClusterPage: "PAGE/CLUSTERPAGE",
    BoatPage: "PAGE/BOAT",
    DealPage: "PAGE/DEAL",
    DealsPage: "PAGE/DEALSPAGE",
    EngagementPage: "PAGE/ENGAGEMENT",
  },
};

const createServerLogger = (): Promise<Logger> => {
  return new Promise((resolve) => {
    import("winston")
      .then((winston) => {
        resolve(
          winston.createLogger({
            level: process.env.LOG_LEVEL || LogLevel.DEBUG,
            format: process.env.LOG_PRETTY_PRINT
              ? winston.format.combine(
                  winston.format.colorize(),
                  winston.format.timestamp(),
                  winston.format.ms(),
                  winston.format.printf((info) => {
                    const ts = new Date(info.timestamp).toISOString();
                    const error = info.formattedError
                      ? { error: info.formattedError }
                      : {};
                    return `${ts} (${info.ms}) [${info.level}] [${info.tags}] ${
                      info.message
                    } ${JSON.stringify(
                      { ...info.payload, ...error },
                      undefined,
                      2
                    )}`;
                  })
                )
              : winston.format.json(),
            transports: [new winston.transports.Console()],
            defaultMeta: { app: "Nebula" },
          })
        );
      })
      .catch((err) => global.console.error("winston cannot be loaded", err));
  });
};

const createInMemoryLogger = (): {
  log: () => void;
  getLogs: () => unknown[][];
} => {
  const inMemoryLogStore: unknown[][] = [];
  const logger = {
    log: (...args: unknown[]) => {
      inMemoryLogStore.push(args);
    },
    getLogs: () => {
      return inMemoryLogStore;
    },
  };

  return logger;
};

export interface LoggerOptions {
  tags?: string[];
}

export type LogPayload = {
  // errors
  variableMessage?: string;
  error?: Error | unknown;
  // technical details
  functionName?: string;
  fileName?: string;
  // technical details
  counter?: number;
  operationName?: string;
  isSuccessful?: boolean;
  cacheKey?: string;
  cacheField?: string;
  cacheKeys?: string[];
  searchProduct?: SearchProductModel;
  // req/res info
  url?: string;
  statusCode?: number;
  res?: {
    responseTimeMs?: number;
  };
  req?: ParsedReq;
  // business related info
  slug?: string;
  localeCode?: string;
  formId?: string;
  availableDates?: string[];
  productCode?: string;
  productCodes?: string[];
  destinationDisplayName?: string;
  currencyCode?: string;
  productId?: number;
  dealId?: number;
  salesCountry?: SalesCountry;
  iso?: string;

  // any other keys that their type is not important and can overwrite
  [key: string]: any;
};

interface Payload extends LogPayload {
  tags: string[];
  formattedError?: {
    stack?: string;
    name?: string;
    message?: string;
  };
}

class ConsoleLogger {
  formatter(message: string, payload: Payload) {
    const tags =
      payload.tags
        .slice(1)
        .reduce(
          (acc, val) => acc + ',"' + val + '"',
          '["' + payload.tags[0] + '"'
        ) + "]";
    return `${tags} | ${message} | `;
  }

  log(level: LogLevel, message: string, payload: Payload) {
    switch (level) {
      case LogLevel.DEBUG:
        global.console.debug(this.formatter(message, payload));
        break;
      case LogLevel.INFO:
        global.console.info(this.formatter(message, payload));
        break;
      case LogLevel.WARN:
        global.console.warn(this.formatter(message, payload));
        break;
      case LogLevel.ERROR:
        if (payload.payload instanceof Error)
          global.console.error(
            this.formatter(message, payload),
            payload.payload
          );
        else if (payload.error)
          global.console.error(this.formatter(message, payload), payload.error);
        else global.console.error(this.formatter(message, payload));
        break;
    }
  }
}

let loggerService: Logger | ConsoleLogger;

const getTags = (options: LoggerOptions) => {
  const env = isBrowser ? logTags.Env.Browser : logTags.Env.Server;
  const layer = options.tags?.find((tag) =>
    Object.values(logTags.Layer).includes(tag)
  );
  const client = options.tags?.find((tag) =>
    Object.values(logTags.Client).includes(tag)
  );
  const feature = options.tags?.find((tag) =>
    Object.values(logTags.Feature).includes(tag)
  );
  const natureOfFunction = options.tags?.find((tag) =>
    Object.values(logTags.Function).includes(tag)
  );
  const page = options.tags?.find((tag) =>
    Object.values(logTags.Page).includes(tag)
  );

  return {
    tags: [env, layer, client, feature, natureOfFunction, page].filter(
      isNotNull
    ),
  };
};

export const logPayloadFormatter = (
  payload: LogPayload | Error | unknown | undefined
) => {
  let errorToFormat: Error | undefined;

  if (payload instanceof Error) {
    errorToFormat = payload as Error;
  } else if ((payload as LogPayload)?.error instanceof Error) {
    errorToFormat = (payload as LogPayload).error as Error;
  }
  return {
    payload,
    formattedError: errorToFormat
      ? {
          stack: errorToFormat.stack,
          name: errorToFormat.name,
          message: errorToFormat.message,
        }
      : {},
  };
};

export const loggerFactory = (options: LoggerOptions) => {
  if (!loggerService) {
    if (isBrowser) {
      loggerService = new ConsoleLogger();
    } else {
      const inMemoryLogger = createInMemoryLogger();
      createServerLogger()
        .then((service) => {
          loggerService = service;
          inMemoryLogger.getLogs().forEach((logItem) => {
            // eslint-disable-next-line prefer-spread
            (loggerService as Logger).log.apply(
              loggerService,
              logItem as Parameters<Logger["log"]>
            );
          });
        })
        .catch((err) =>
          global.console.error("cannot create a logger service", err)
        );
      loggerService = inMemoryLogger as unknown as Logger;
    }
  }

  return {
    info(message: string, payload?: LogPayload) {
      loggerService.log(LogLevel.INFO, message, {
        ...getTags(options),
        ...logPayloadFormatter(payload),
      });
    },
    warn(message: string, payload?: LogPayload) {
      loggerService.log(LogLevel.WARN, message, {
        ...getTags(options),
        ...logPayloadFormatter(payload),
      });
    },
    debug(message: string, payload?: LogPayload) {
      loggerService.log(LogLevel.DEBUG, message, {
        ...getTags(options),
        ...logPayloadFormatter(payload),
      });
    },
    error(message: string, payload?: LogPayload | Error | unknown) {
      loggerService.log(LogLevel.ERROR, message, {
        ...getTags(options),
        ...logPayloadFormatter(payload),
      });
    },
  };
};
