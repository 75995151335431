enum CookieKey {
  OriginCountry = "Origin-Country",
  OriginCountryName = "originCountryName",
  EnduranceCountry = "PEAK_COUNTRY",
  PreferredRegion = "PEAK_PREFERRED_REGION",
  Agent = "IG_AGENT",
  EnduranceBuyingAgent = "PEAK_BUYING_AGENT_ID",
  EnduranceAgent = "PeakAgentCookie",
  EnduranceCurrency = "PEAK_CURRENCY",
  FirstName = "First_Name",
}

export default CookieKey;
