import { getBaseLink } from "./getBaseLink";
import { isFullyQualifiedLink } from "./isFullyQualifiedLink";
import { isBlogLink } from "./isBlogLink";
import { CurrencyCode } from "~~/lib/types/Currency";

/**
 * Creates a link to be used by the website.
 * Accounts for currencyCode, fully qualified links, non-fully qualified links missing initial / and blog links
 *
 * @param localeCode The locale
 * @param currencyCode The optional currency
 * @returns The url
 */
export const createLink =
  (localeCode: string, currencyCode?: CurrencyCode) => (url?: string) => {
    const currencyPath = currencyCode ? "/" + currencyCode : "";

    if (!url) {
      return `/${localeCode}${currencyPath}`;
    }

    if (isFullyQualifiedLink(url)) {
      return url;
    }

    if (!url.startsWith("/")) {
      url = "/" + url;
    }

    if (isBlogLink(url)) {
      return url;
    }

    return `/${localeCode}${currencyPath}${getBaseLink(url)}`;
  };
