import { getCacheParamForFetch } from "./cache/getCacheParamForFetch";

export const contextualFetch = ((url, options) => {
  return $fetch(url, {
    ...options,
    params: {
      ...options?.params,
      ...getCacheParamForFetch(),
    },
  });
}) as typeof $fetch;
