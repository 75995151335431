export const getCacheParamForFetch = (): {
  cache?: string | null;
} => {
  if (process.client) {
    const searchParams = new URLSearchParams(window.location?.search || "");

    if (searchParams.has("cache")) {
      return {
        cache: searchParams.get("cache"),
      };
    }
  }
  return {};
};
