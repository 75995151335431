export const setItemtoLocalStorage = async (key: string, value: string) => {
  await window.localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key: string): string | null => {
  const item = window.localStorage.getItem(key);
  return item;
};

export const removeItemFromLocalStorage = async (key: string) => {
  await window.localStorage.removeItem(key);
};
