import { getDefaultLocale } from "./getDefaultLocale";
import locales from "~~/config/locales";
import Locale from "~~/lib/types/Locale";

export const getLocaleByCountry = (country: string): Locale => {
  const locale = locales.find(({ countries }: Locale) =>
    countries.includes(country?.toLowerCase())
  );

  return locale ?? getDefaultLocale();
};
